@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon.eot?-f7adx6');
	src:url('../fonts/icomoon.eot?#iefix-f7adx6') format('embedded-opentype'),
		url('../fonts/icomoon.woff?-f7adx6') format('woff'),
		url('../fonts/icomoon.ttf?-f7adx6') format('truetype'),
		url('../fonts/icomoon.svg?-f7adx6#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-pencil:before {
	content: "\e905";
	color: black;
}

.icon-plus:before {
	content: "\ea0a";
}

.icon-minus:before {
	content: "\ea0b";
}

.icon-cross:before {
	content: "\ea0f";
	color: red;
}

.icon-checkmark:before {
	content: "\ea10";
	color: green;
}

.icon-arrow-up-right2:before {
	content: "\ea3b";
}

