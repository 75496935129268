html {
	@media screen and (max-width:1224px) {
	}

	@media screen and (max-width:1024px) {
		h1 {
			font-size: 3em;
			margin: 30px 0;
		}

		h2 {
			font-size: 2.5em;
			margin: 30px 0 20px;
		}

		h3 {
			font-size: 2em;
			margin: 30px 0 15px;
		}

		h4 {
			font-size: 1.5em;
			margin: 30px 0 10px;
		}

		h5 {
			font-size: 1em;
			margin: 30px 0 5px;
		}

		h6 {
			font-size: 0.5em;
			margin: 30px 0 5px;
		}

		form {
			fieldset {
				div {
					label.onfocus {
						display: block;
						width: 100%;
						margin-bottom: 0;
					}

					input {
						width: 100%;
						margin-top: 0;
					}
					textarea{
						width: 100%;
						margin: 0;
					}
					select {
						width: 100%;
					}

				}

				.btn {
					width: 100%;
				}

			}

		}

	}

	@media screen and (max-width:480px) {
		form{
			img{
				max-width: 250px;
			}
		}
	}

}