header {
	width: 100%;
	text-transform: uppercase;
	background-color: #000;
	position: fixed;
	top: 0;
	.logo{
		height: 50px;
		float: left;
			img{
				height: 50px;
			}
		}
	nav {
		width: 80%;
		margin: auto;
		height: 50px;
		ul {
			margin: 0;
			padding: 0;
			.clearfix();

			li {
				float: left;
				list-style: none;
				display: inline-block;

				a {
					text-decoration: none;
					text-align: center;
					height: 50px;
					padding: 15px 30px;
					display: block;
					color: white;
					margin: 0;
					font-family: 'areal';
					font-size: 16px;
					&:hover{
						background-color: #232323;
					}
				}
				&.active {
					color: white;
					background-color: #232323;
					text-decoration: underline;
				}
			}
		}
	}
}
@media screen and (max-width : 960px){
	header{
		nav{
			padding: 0;
			margin: 0;
			width: 100%;
			.toggler{
				display: block;
				width: 60px;
				height: 50px;
				float: right;
				padding-top: 5px;
				span{
					height: 2px;
					display: block;
					margin:  10px;
					background-color: white;
				}
				&:hover{
				background-color: #232323;
			}
			}
			ul{
				display: none;
			}
			ul.clicked,
			ul.hover{
				display: block;
				li{
					float: none;
					width: 100%;
					background-color: #000000;
				}
			}
		}
	}
}