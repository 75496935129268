*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
html {
  height: 100%;
  font-size: 14px;
}
body {
  width: 100%;
  min-width: 320px;
  height: 100%;
  overflow-y: scroll;
  margin: 0;
  background-color: #efefef;
}
body > .bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: no-repeat center center;
  background-size: cover;
}
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
::-webkit-selection {
  background-color: #000000;
  color: #ffffff;
}
::-moz-selection {
  background-color: #000000;
  color: #ffffff;
}
::-ie-selection {
  background-color: #000000;
  color: #ffffff;
}
::-o-selection {
  background-color: #000000;
  color: #ffffff;
}
::selection {
  background-color: #000000;
  color: #ffffff;
}
@font-face {
  font-family: 'areal';
  src: url('../fonts/areal/areal.eot') format('eot'), url('../fonts/areal/areal.woff') format('woff'), url('../fonts/areal/areal.ttf') format('truetype'), url('../fonts/areal/areal.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'catalog_italic';
  src: url('../fonts/catalog/catalog_italic.eot') format('eot'), url('../fonts/catalog/catalog_italic.woff') format('woff'), url('../fonts/catalog/catalog_italic.ttf') format('truetype'), url('../fonts/catalog/catalog_italic.svg#svgFontName') format('svg');
}
@font-face {
  font-family: 'catalog_regular';
  src: url('../fonts/catalog/catalog_regular.eot') format('eot'), url('../fonts/catalog/catalog_regular.woff') format('woff'), url('../fonts/catalog/catalog_regular.ttf') format('truetype'), url('../fonts/catalog/catalog_regular.svg#svgFontName') format('svg');
}
* {
  font-family: 'catalog_regular';
}
h1 {
  font-family: 'areal';
  font-size: 3.5em;
  margin: 30px 0;
  text-transform: uppercase;
}
h2 {
  font-family: 'areal';
  font-size: 3em;
  margin: 30px 0 20px;
  text-transform: uppercase;
}
h3 {
  font-family: 'areal';
  font-size: 2.5em;
  margin: 30px 0 15px;
  text-transform: uppercase;
}
h4 {
  font-family: 'areal';
  font-size: 2em;
  margin: 30px 0 10px;
  text-transform: uppercase;
}
h5 {
  font-family: 'areal';
  font-size: 1.5em;
  margin: 30px 0 5px;
  text-transform: uppercase;
}
h6 {
  font-family: 'areal';
  font-size: 1em;
  margin: 30px 0 5px;
  text-transform: uppercase;
}
@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?-f7adx6');
  src: url('../fonts/icomoon.eot?#iefix-f7adx6') format('embedded-opentype'), url('../fonts/icomoon.woff?-f7adx6') format('woff'), url('../fonts/icomoon.ttf?-f7adx6') format('truetype'), url('../fonts/icomoon.svg?-f7adx6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pencil:before {
  content: "\e905";
  color: black;
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-cross:before {
  content: "\ea0f";
  color: red;
}
.icon-checkmark:before {
  content: "\ea10";
  color: green;
}
.icon-arrow-up-right2:before {
  content: "\ea3b";
}
#body {
  width: 80%;
  margin: -50px auto 0;
  padding: 170px 0;
  min-height: 100%;
  max-width: 650px;
}
#body div.prev_mail {
  padding: 8px;
  margin: 10px 0 20px;
  border: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  background-color: white;
}
article#thx {
  background-color: white;
  border-radius: 5px;
  padding: 8px;
}
article#thx .thx_logo {
  width: 50px;
}
article#thx a {
  color: black;
}
article#thx .hidden {
  display: none;
}
.select-view {
  height: 45px;
  margin: 0 0 5px 0;
  background-color: white;
}
.select-view span {
  width: 15%;
  display: block;
  float: left;
  color: black;
  overflow: hidden;
  padding: 0 15px;
}
.select-view span.large {
  width: 27.5%;
}
.select-view:hover {
  background-color: #dfdfdf;
}
.select-view.top {
  font-weight: bold;
  background-color: #000;
  padding: 10px;
}
.select-view.top span {
  color: white;
}
.select-view a {
  display: block;
  padding: 10px;
}
header {
  width: 100%;
  text-transform: uppercase;
  background-color: #000;
  position: fixed;
  top: 0;
}
header .logo {
  height: 50px;
  float: left;
}
header .logo img {
  height: 50px;
}
header nav {
  width: 80%;
  margin: auto;
  height: 50px;
}
header nav ul {
  margin: 0;
  padding: 0;
  *zoom: 1;
}
header nav ul:before,
header nav ul:after {
  display: table;
  content: "";
  line-height: 0;
}
header nav ul:after {
  clear: both;
}
header nav ul:before,
header nav ul:after {
  display: table;
  content: "";
  line-height: 0;
}
header nav ul:after {
  clear: both;
}
header nav ul li {
  float: left;
  list-style: none;
  display: inline-block;
}
header nav ul li a {
  text-decoration: none;
  text-align: center;
  height: 50px;
  padding: 15px 30px;
  display: block;
  color: white;
  margin: 0;
  font-family: 'areal';
  font-size: 16px;
}
header nav ul li a:hover {
  background-color: #232323;
}
header nav ul li.active {
  color: white;
  background-color: #232323;
  text-decoration: underline;
}
@media screen and (max-width: 960px) {
  header nav {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  header nav .toggler {
    display: block;
    width: 60px;
    height: 50px;
    float: right;
    padding-top: 5px;
  }
  header nav .toggler span {
    height: 2px;
    display: block;
    margin: 10px;
    background-color: white;
  }
  header nav .toggler:hover {
    background-color: #232323;
  }
  header nav ul {
    display: none;
  }
  header nav ul.clicked,
  header nav ul.hover {
    display: block;
  }
  header nav ul.clicked li,
  header nav ul.hover li {
    float: none;
    width: 100%;
    background-color: #000000;
  }
}
footer {
  width: 100%;
  background-color: #E6E6E6;
}
footer nav {
  width: 80%;
  margin: auto;
  height: 45px;
}
footer nav ul {
  margin: 0;
  padding: 0;
  *zoom: 1;
}
footer nav ul:before,
footer nav ul:after {
  display: table;
  content: "";
  line-height: 0;
}
footer nav ul:after {
  clear: both;
}
footer nav ul:before,
footer nav ul:after {
  display: table;
  content: "";
  line-height: 0;
}
footer nav ul:after {
  clear: both;
}
footer nav ul li {
  float: left;
  list-style: none;
  display: inline-block;
}
footer nav ul li a {
  text-decoration: none;
  text-align: center;
  display: inline-block;
  height: 45px;
  padding: 11px 30px;
  display: block;
  color: black;
  margin: 0;
  font-family: 'roboto_light';
  font-size: 15px;
}
footer nav ul li a:hover {
  color: #80D1FF;
}
form img {
  display: block;
  max-width: 350px;
  max-height: 500px;
}
form fieldset {
  border: none;
  margin: 0 0 150px 0;
  padding: 0;
}
form fieldset div {
  *zoom: 1;
}
form fieldset div:before,
form fieldset div:after {
  display: table;
  content: "";
  line-height: 0;
}
form fieldset div:after {
  clear: both;
}
form fieldset div:before,
form fieldset div:after {
  display: table;
  content: "";
  line-height: 0;
}
form fieldset div:after {
  clear: both;
}
form .onfocus {
  font-family: 'roboto_light';
  width: 20%;
  display: block;
  float: left;
  padding: 11px 0 9px;
  margin: 10px 0 10px;
}
form textarea {
  padding: 8px;
  display: block;
  margin: 10px 0 20px;
  border: none;
  border-radius: 0;
  outline: none;
  width: 80%;
  float: left;
  resize: none;
}
form input,
form .info {
  padding: 8px;
  display: block;
  margin: 10px 0 20px;
  border: none;
  border-radius: 0;
  outline: none;
  width: 80%;
  float: left;
}
form input[type="reset"],
form .info[type="reset"],
form input[type="submit"],
form .info[type="submit"] {
  float: none;
}
form input.upload,
form .info.upload {
  height: 40px;
  padding: 8px;
  display: block;
  margin: 10px 0 20px;
  border: none;
  border-radius: 0;
  outline: none;
  width: 78%;
  background-color: white;
}
form input.upload_2,
form .info.upload_2 {
  width: 75%;
  float: left;
}
form .hasDatepicker {
  width: 73%;
}
form .ui-datepicker-trigger {
  width: 5%;
  margin-left: 2%;
  margin-top: 15px;
}
form select {
  height: 40px;
  padding: 8px;
  display: block;
  width: 100%;
  margin: 10px 0 20px;
  border: none;
  border-radius: 0;
  outline: none;
  width: 80%;
}
form fieldset div.socialmedia {
  margin-top: 30px;
}
form fieldset div.socialmedia input[type="checkbox"] {
  float: left;
  width: 5%;
  margin-bottom: 5px;
  display: inline-block;
  width: auto;
  margin-right: 10px;
  margin-top: 21px;
}
form fieldset div.socialmedia label {
  display: block;
  width: 15%;
  margin-top: 10px;
  float: left;
  padding-top: 11px;
}
form fieldset div.socialmedia input[type="text"] {
  display: none;
  float: right;
}
form fieldset div.socialmedia input[type="checkbox"]:checked ~ input[type="text"] {
  display: block;
}
form fieldset div.socialmedia ul {
  margin: 0;
}
form fieldset div.socialmedia > div {
  margin-top: 10px;
}
@media screen and (max-width: 1024px) {
  form fieldset div.socialmedia label {
    width: 90%;
  }
}
.no_error {
  display: block;
  color: green;
}
.no_error span {
  font-family: 'roboto_light';
  padding: 0 0 0 10px;
}
.error {
  color: red;
}
.error span {
  font-family: 'roboto_light';
  padding: 0 0 0 10px;
}
.btn {
  background-color: #000;
  color: white;
  height: 40px;
  padding: 10px 8px 8px 8px;
  border: none;
  border-radius: 0;
  outline: none;
  text-decoration: none;
  text-align: center;
  margin: 20px auto;
  display: block;
  width: 30%;
}
.btn:hover {
  opacity: 0.8;
  /* color: white; */
}
.ui-widget-header .ui-icon {
  background-image: url("/newbie/dest/img/vendor//ui-icons_ffffff_256x240.png");
}
.kind {
  margin-bottom: 30px;
}
.add_kind,
.add_pensionskasse {
  margin-top: 20px;
}
.delet_kind,
.delet_pensionskasse {
  display: none;
  margin-top: 20px;
}
.delet_pensionskasse {
  margin-bottom: 30px;
}
#ahv {
  margin-top: 30px;
}
.foto_prev {
  display: block;
  margin: auto;
  max-width: 250px;
}
.foto_prev[src=""] {
  display: none;
}
.foto_prev[src="../uploads/.jpg"] {
  display: none;
}
.foto_prev[src="../uploads/"] {
  display: none;
}
.win {
  display: none;
}
.mac {
  display: none;
}
.vis {
  display: block;
}
.prev {
  background-color: white;
  height: 40px;
  padding: 8px;
  width: 100%;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
}
form a {
  text-decoration: none;
}
form .prev_edit {
  width: 75%;
  float: left;
}
form .prev_edit.locked {
  color: #808080;
}
form .edit,
form .edit_img,
form .edit_picker {
  height: 40px;
  display: block;
  width: 4%;
  float: left;
  padding: 14px 0 0 0;
  text-align: center;
  border-radius: 5px;
  margin: 10px 0 0 1%;
}
form .edit_img {
  float: right;
  margin-top: 0;
}
form #worker_foto {
  width: 20%;
  display: block;
  margin: auto;
}
.hidden {
  display: none;
}
.visible {
  display: block;
}
select.prev {
  width: 75%;
}
#send_mail {
  margin-top: 150px;
}
@media screen and (max-width: 1024px) {
  html h1 {
    font-size: 3em;
    margin: 30px 0;
  }
  html h2 {
    font-size: 2.5em;
    margin: 30px 0 20px;
  }
  html h3 {
    font-size: 2em;
    margin: 30px 0 15px;
  }
  html h4 {
    font-size: 1.5em;
    margin: 30px 0 10px;
  }
  html h5 {
    font-size: 1em;
    margin: 30px 0 5px;
  }
  html h6 {
    font-size: 0.5em;
    margin: 30px 0 5px;
  }
  html form fieldset div label.onfocus {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }
  html form fieldset div input {
    width: 100%;
    margin-top: 0;
  }
  html form fieldset div textarea {
    width: 100%;
    margin: 0;
  }
  html form fieldset div select {
    width: 100%;
  }
  html form fieldset .btn {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  html form img {
    max-width: 250px;
  }
}
