footer{
	width: 100%;
	background-color: #E6E6E6;
	nav{
		width: 80%;
		margin: auto;
		height: 45px;
		ul{
			margin: 0;
			padding: 0;
			.clearfix();
			li{
				float: left;
				list-style: none;
				display: inline-block;
				a{
					text-decoration: none;
					text-align: center;
					display: inline-block;
					height: 45px;
					padding: 11px 30px;
					display: block;
					color: black;
					margin: 0;
					font-family: 'roboto_light';
					font-size: 15px;
					&:hover{
						color: #80D1FF;
					}
				}
			}
		}
	}
}