.clearfix() {
	*zoom: 1;
	&:before, &:after { display: table; content: ""; line-height: 0; }
	&:after { clear: both; }
}

.css3(@property, @value) {
	-less-helper: ~'; -webkit-@{property}: 	@{value};';
	-less-helper: ~'; -moz-@{property}: 	@{value};';
	-less-helper: ~'; -ms-@{property}: 		@{value};';
	-less-helper: ~'; -o-@{property}: 		@{value};';
	-less-helper: ~'; @{property}: 			@{value};';
}