html {
	height: 100%;
	font-size: 14px;
}
body {
	width: 100%;
	min-width: 320px;
	height: 100%;
	overflow-y: scroll;
	margin: 0;
	background-color: #efefef;

	> .bg {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: no-repeat center center;
		background-size: cover;
	}
}
.wrapper {
	position: relative;
	width: 100%;
	height: 100%;
}
//Selection//////////////////////////////////////////////////////////////////////////////////
::-webkit-selection {
	background-color: #000000;
	color: #ffffff;
}
::-moz-selection {
	background-color: #000000;
	color: #ffffff;
}
::-ie-selection {
	background-color: #000000;
	color: #ffffff;
}
::-o-selection {
	background-color: #000000;
	color: #ffffff;
}
::selection {
	background-color: #000000;
	color: #ffffff;
}
//Fonts//////////////////////////////////////////////////////////////////////////////////
@font-face { font-family: 'areal';
	src: url('../fonts/areal/areal.eot')  format('eot'),
    	url('../fonts/areal/areal.woff')  format('woff'),
   		url('../fonts/areal/areal.ttf')   format('truetype'),
    	url('../fonts/areal/areal.svg#svgFontName')  format('svg'); 
}
@font-face { font-family: 'catalog_italic';
	src: url('../fonts/catalog/catalog_italic.eot')  format('eot'),
    	url('../fonts/catalog/catalog_italic.woff')  format('woff'),
   		url('../fonts/catalog/catalog_italic.ttf')   format('truetype'),
    	url('../fonts/catalog/catalog_italic.svg#svgFontName')  format('svg'); 
}
@font-face { font-family: 'catalog_regular';
	src: url('../fonts/catalog/catalog_regular.eot')  format('eot'),
    	url('../fonts/catalog/catalog_regular.woff')  format('woff'),
   		url('../fonts/catalog/catalog_regular.ttf')   format('truetype'),
    	url('../fonts/catalog/catalog_regular.svg#svgFontName')  format('svg'); 
}
*{
	font-family: 'catalog_regular';
}
h1 {
	font-family: 'areal';
	font-size: 3.5em;
	margin: 30px 0;
	text-transform: uppercase;
}
h2 {
	font-family: 'areal';
	font-size: 3em;
	margin: 30px 0 20px;
	text-transform: uppercase;
}
h3 {
	font-family: 'areal';
	font-size: 2.5em;
	margin: 30px 0 15px;
	text-transform: uppercase;
}
h4 {
	font-family: 'areal';
	font-size: 2em;
	margin: 30px 0 10px;
	text-transform: uppercase;
}
h5 {
	font-family: 'areal';
	font-size: 1.5em;
	margin: 30px 0 5px;
	text-transform: uppercase;
}
h6 {
	font-family: 'areal';
	font-size: 1em;
	margin: 30px 0 5px;
	text-transform: uppercase;
}

