form {
	img{
		display: block;
	    max-width: 350px;
	    max-height: 500px;
	}
	fieldset{
		border: none;
		margin: 0 0 150px 0;
		padding: 0;
		div{
			.clearfix();
		}
	}
	.onfocus {
		font-family: 'roboto_light';
		width: 20%;
		display: block;
		float: left;
		padding: 11px 0 9px;
		margin: 10px 0 10px;
	}
	textarea{
		padding: 8px;
		display: block;
		margin: 10px 0 20px;
		border: none;
		border-radius: 0;
		outline: none;
		width: 80%;
		float: left;
		resize: none;
	}
	input,
	.info {
		padding: 8px;
		display: block;
		margin: 10px 0 20px;
		border: none;
		border-radius: 0;
		outline: none;
		width: 80%;
		float: left;
		&[type="reset"], &[type="submit"] { float: none; }
		&.upload{
			height: 40px;
			padding: 8px;
			display: block;
			margin: 10px 0 20px;
			border: none;
			border-radius: 0;
			outline: none;
			width: 78%;
			background-color: white;
		}
		&.upload_2{
			width: 75%;
			float: left;
		}
	}
	.hasDatepicker{
		width: 73%;
	}
	.ui-datepicker-trigger{
		width: 5%;
		margin-left: 2%;
		margin-top: 15px;
	}
	select {
		height: 40px;
		padding: 8px;
		display: block;
		width: 100%;
		margin: 10px 0 20px;
		border: none;
		border-radius: 0;
		outline: none;
		width: 80%;
	}
	fieldset div.socialmedia{
		margin-top: 30px;
		input[type="checkbox"]{
			float: left;
			width: 5%;
			margin-bottom: 5px;
			display: inline-block;
		    width: auto;
		    margin-right: 10px;
		    margin-top: 21px;
		}
		label{
			display: block;
			width: 15%;
			margin-top: 10px;
			float: left;
			padding-top: 11px; 
		}
		input[type="text"]{
			display: none;
			float: right;
		}
		input[type="checkbox"]:checked{
			~input[type="text"]{
				display: block;
			}
		}
		ul{
			margin: 0;
		}
		>div{
			margin-top: 10px;
		}
		@media screen and (max-width : 1024px){
			label{
				width: 90%;
			}
		}
	}
}
.no_error{
	display: block;
	color: green;
	span{
		font-family: 'roboto_light';
		padding: 0 0 0 10px;
	}
}
.error{
	color: red;
	span{
		font-family: 'roboto_light';
		padding: 0 0 0 10px;
	}
}
.btn {
	background-color: #000;
	color: white;
	height: 40px;
	padding: 10px 8px 8px 8px;
	border: none;
	border-radius: 0;
	outline: none;
	text-decoration: none;
	text-align: center;
	margin: 20px auto;
	display: block;
	width: 30%;
	&:hover {
		opacity: 0.8;
		/* color: white; */
	}
}

.ui-widget-header .ui-icon{
	background-image: url("/newbie/dest/img/vendor//ui-icons_ffffff_256x240.png");
}
.kind{
	margin-bottom: 30px;
}
.add_kind,
.add_pensionskasse{
	margin-top: 20px;
}
.delet_kind,
.delet_pensionskasse{
	display: none;
	margin-top: 20px;
}
.delet_pensionskasse{
	margin-bottom: 30px;
}
#ahv{
	margin-top: 30px;
}
.foto_prev{
	display: block;
	margin: auto;
	max-width: 250px;
	&[src=""]{
		display: none;
	}
	&[src="../uploads/.jpg"]{
		display: none;
	}
	&[src="../uploads/"]{
		display: none;
	}
}
.win{
	display: none;
}
.mac{
	display: none;
}
.vis{
	display: block;
}
