.prev {
	background-color: white;
	height: 40px;
	padding: 8px;
	width: 100%;
	margin: 10px 0;
	border: none;
	border-radius: 5px;
}

form{
	a{
		text-decoration: none;
	}
	.prev_edit{
		width: 75%;
		float: left;
		&.locked{
			color: rgb(128, 128, 128)
		}
	}
	.edit,
	.edit_img,
	.edit_picker{
		height: 40px;
		display: block;
		width: 4%;
		float: left;
		padding: 14px 0 0 0;
		text-align: center;
		border-radius: 5px;
		margin: 10px 0 0 1%;
	}
	.edit_img{
		float: right;
		margin-top: 0;
	}
	#worker_foto{
		width: 20%;
		display: block;
		margin: auto;
	}
}
.hidden{
	display: none;
}
.visible{
	display: block;
}
select.prev{
	width: 75%;
}
#send_mail{
	margin-top: 150px;
}