#body {
	width: 80%;
	margin: -50px auto 0;
	padding: 170px 0;
	min-height: 100%;
	max-width: 650px;

	div.prev_mail{
		padding: 8px;
		margin: 10px 0 20px;
		border: none;
		border-radius: 5px;
		outline: none;
		width: 100%;
		background-color: white;
	}
}
article#thx{
	background-color: white;
	border-radius: 5px;
	padding: 8px;
	.thx_logo{
	width: 50px;
	}
	a{
		color: black;
	}
	.hidden{
		display: none;
	}
}
.select-view{
	height: 45px;
    margin: 0 0 5px 0;
    background-color: white;
    span{
    	width: 15%;
	    display: block;
	    float: left;
	    color: black;
	    overflow: hidden;
	    padding: 0 15px;
	    &.large{
	    	width: 27.5%;
	    }
    }
    &:hover{
    	background-color: #dfdfdf;
    }
    &.top{
    	font-weight: bold;
    	background-color: #000;
    	padding: 10px;
    	span{
			color: white;
    	}
    }
    a{
    	display: block;
    	padding: 10px;
    }
}